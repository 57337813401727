import {
  Box,
  Button,
  Grid,
  GridItem,
  Heading,
  Text,
  Link,
  Stack,
  HStack,
  useBreakpointValue,
} from '@chakra-ui/react';
import * as React from 'react';
import { graphql, PageProps } from 'gatsby';

import { SocialIcon } from 'react-social-icons';
import Layout from '../layout';
import Head from '../components/head';
import { ContactForm } from '../components/forms';

interface ContactProps extends PageProps {
  data: {
    site: {
      siteMetadata: {
        socialMedia: Array<Record<'name' | 'link', string>>;
      };
    };
  };
}

const Contact: React.FC<ContactProps> = ({ data }) => {
  const contactAreaCols = useBreakpointValue({
    md: 'repeat(2, 1fr)',
    base: '1fr',
  });
  const { siteMetadata } = data.site;

  return (
    <Layout headerColorScheme="dark">
      <Head title="Contact Jamestown" />

      <svg width="0" height="0">
        <defs>
          <clipPath id="myCurve" clipPathUnits="objectBoundingBox">
            <path
              d="M 0,1
									L 0,0
									L 1,0
									L 1,1
									C .65 .8, .35 .8, 0 1
									Z"
            />
          </clipPath>
        </defs>
      </svg>

      <Box
        padding="120px calc(var(--cp) + 1.5rem) 8rem"
        clipPath="url(#myCurve)"
        align="center"
        color="#fff"
        bg="purple"
      >
        <Heading size="3xl" color="white">
          Contact Us
        </Heading>
        <Text
          width="520px"
          fontWeight={500}
          maxWidth="100%"
          mt={4}
          fontSize="16px"
        >
          If you have any issues, would like to request a feature, or want to
          contact our Customer Care Representative team, we’re ready to help.
        </Text>
      </Box>

      <Grid
        gap={20}
        padding="6rem calc(var(--cp) + 1.5rem)"
        templateColumns={contactAreaCols}
        sx={{ 'h3.title': { color: 'purple' } }}
      >
        <GridItem as="section">
          <Stack mb={10}>
            <Heading size="xl" as="h3" m={0}>
              Send us a message
            </Heading>
            <Text fontSize="15px" fontWeight={500}>
              Jamestown has one of the best 24/7 customer support teams. You can
              get in touch with us through our contact form.
            </Text>
          </Stack>
          <ContactForm />
        </GridItem>

        <GridItem as="section">
          <Heading size="xl" as="h3" className="title" mb={3}>
            Chat with Us
          </Heading>
          <Text mb={5} fontWeight={500}>
            If you’d prefer to chat in real time with our support team, we’re
            online Monday to Friday whatever your time zone. we’re online Monday
            to Friday whatever your time zone.
          </Text>
          <Button
            as={Link}
            color="purple"
            bg="aquamarine"
            borderColor="aquamarine"
            _hover={{ textDecoration: 'none' }}
          >
            Chat with us
          </Button>

          <Stack mt={14} spacing={0}>
            <Heading size="md" as="h3" className="title" mb={5}>
              Follow us on
            </Heading>
            <HStack
              mb={3}
              sx={{
                '.social-icon': {
                  width: '32px !important',

                  svg: {
                    borderRadius: '10px !important',
                  },
                },
              }}
            >
              {siteMetadata.socialMedia.map((account) => (
                <SocialIcon
                  key={account.name}
                  url={account.link}
                  fgColor="#ffffff"
                  bgColor="#1B0A4B"
                  network={account.name}
                />
              ))}
            </HStack>
          </Stack>
        </GridItem>
      </Grid>
    </Layout>
  );
};

export const query = graphql`
  query FooterQuery {
    site {
      siteMetadata {
        socialMedia {
          name
          link
        }
      }
    }
  }
`;

export default Contact;
